import React, { useEffect } from "react";
import Seo from "../components/seo";
import { Helmet } from "react-helmet";
import axios from "axios";

const GetAppPage = () => {
  useEffect(() => {
    // Extract query parameters from URL
    const queryParams = new URLSearchParams(window.location.search);
    const payload = Object.fromEntries(queryParams.entries());

    // API call
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${process.env.GATSBY_SYNKLI_API_ENDPOINT_DEV}/site/handle-email-click`,
          payload, // Pass payload as JSON
          {
            headers: {
              "Content-Type": "application/json", // Ensure JSON content type
            },
          }
        );
  
        console.log("API Response:", response.data.data);
        if (response.data.data.redirect_url) {
          window.open(response.data.data.redirect_url, "_blank", "noopener,noreferrer");
          window.setTimeout(function(){
            window.location.href = response.data.data.fallbackUrl;
          }, 2000)
        }
      } catch (error) {
        console.error("Error calling API:", error);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures this runs only on page load

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Seo title="Get App" />
    </>
  );
};

export default GetAppPage;
